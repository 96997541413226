import { H3Error } from 'h3'
import { useGetCurrentUserProfile } from '~/domains/users/queries'

export default defineNuxtRouteMiddleware(async () => {
  const localePath = useLocalePath()

  const result = await useGetCurrentUserProfile()
    .then(user => ({ user }))
    .catch((err) => {
      if (err instanceof H3Error) {
        if (err.statusCode === 401) {
          return {
            navigate: localePath('/login'),
          }
        }

        // /api/users/me will return a 404 if the user hasn't completed their profile
        if (err.statusCode === 404) {
          return {
            navigate: localePath('/auth/create-profile'),
          }
        }
      }

      throw err
    })

  if ('navigate' in result) {
    return await navigateTo(result.navigate, {
      replace: true,
    })
  }
})
